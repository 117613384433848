'use client';

import { Button } from '@streetferret/ui-web/button';
import { Container } from '@streetferret/ui-web/container';
import { Frown } from 'lucide-react';
import { useEffect } from 'react';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <Container className="grid items-center place-content-center h-full justify-center min-h-screen">
      <div className="space-y-3">
        <div className="flex items-center flex-col">
          <Frown className="w-20 h-20 text-foreground" />
          <h2>Something went wrong!</h2>
        </div>
        <Button className="w-full" onClick={() => reset()}>
          Try again
        </Button>
      </div>
    </Container>
  );
}
